<template>
  <div>
    <general-table
      :api-url="APIURL"
      :add-type="addType"
      :columns="columns"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="false"
      :block-content="false"
      :edit-content="false"
      :viw-component="viwComponent"
      :view-content="true"
      :search-box="false"
      :type="type"
      :selectable="false"
    >
      <template #cell(name)="data">
        {{ data.item.name_ar }}
      </template>
      <template #cell(country_name)="data">
        <span>
          <img
            v-img
            width="30"
            :src="data.item.flag_image"
          > {{ data.item.country_name }}</span>
      </template>
      <template #cell(quantity_limit)="data">
        <span v-if="data.item.quantity_limit!==null">{{ data.item.quantity_limit }}</span>
        <span v-else>Unlimted</span>
      </template>
      <template #cell(sections)="data">
        <router-link :to="{ name: 'campaign-sections', params: { id: data.item.id } }">
          <span
            title="Add sections"
          >
            <feather-icon
              icon="EditIcon"
              class="cursor-pointer"
            />
          </span>
        </router-link>

      </template>
      <template #cell(images)="data">

        <img
          v-if="data.item.images.length>0"
          v-img
          width="70"
          :src="data.item.images[0].url"
        >
      </template>
      <template
        #cell(is_active)="data"
      >
        <b-badge
          class="change-status"
          :variant="changeVaraint(data.item.is_active)"
        >
          {{ getTypes(data.item.is_active) }}
        </b-badge>
      </template>
    </general-table>

  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'admin/flash_campaigns',
      addType: 'Add New Flash Campaign',
      type: 'page',
      urlType: 'campaigns',
      addComponentName: 'add-flash-campaigns',
      viwComponent: 'show-flash-campaign',
      columns: [
        { key: 'id' },
        { key: 'images', label: 'Image' },
        { key: 'product_name', label: 'Product name' },
        { key: 'gift_name', label: 'Gift name' },
        { key: 'country_name', label: 'Country' },
        { key: 'quantity_limit', label: 'Quantity limit' },
        { key: 'sections', label: 'Sections' },
        { key: 'actions' },
      ],
    }
  },
  methods: {
    changeVaraint(type) {
      // eslint-disable-next-line eqeqeq
      if (type === 1) return 'success'

      return 'danger'
    },

    getTypes(type) {
      if (type === 1) {
        return 'Displayed'
      } if (type === 0) {
        return 'Hidden'
      }
      return 'Displayed'
    },

  },
}
</script>

<style>

</style>
